import React from 'react'

import Layout from 'components/Layout'

export default () => (
  <Layout>
    <oma-grid-row>
      <section className='section'>
        <h2>Sidan kunde inte hittas</h2>
        <p>
          Ajdå, den här sidan kunde inte hittas. Titta gärna runt på resten av
          hemsidan istället. Kontakta oss om det är någonting som verkar vara
          fel.
        </p>
      </section>
    </oma-grid-row>
  </Layout>
)
